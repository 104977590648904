import { useRoutes, Navigate } from 'react-router-dom'
import { Suspense, lazy } from 'react'

const SuspenseComponent = Component => props => (
  <Suspense fallback={<span>loading...</span>}>
    <Component props={props}></Component>
  </Suspense>
)

const Layout = SuspenseComponent(lazy(() => import('@/views/Layout'))) 
const Login = SuspenseComponent(lazy(() => import('@/views/Login')))
const Signup = SuspenseComponent(lazy(() => import('@/views/Signup')))
const PasswordReset = SuspenseComponent(lazy(() => import('@/views/PasswordReset')))
const Main = SuspenseComponent(lazy(() => import('@/views/Main')))
const Wealth = SuspenseComponent(lazy(() => import('@/views/Wealth')))
const WealthProduct = SuspenseComponent(lazy(() => import('@/views/Wealth/Product')))
const WealthDetail = SuspenseComponent(lazy(() => import('@/views/Wealth/Detail')))
const Mall = SuspenseComponent(lazy(() => import('@/views/Mall')))
const MallDetail = SuspenseComponent(lazy(() => import('@/views/Mall/Detail')))
const MallExchange = SuspenseComponent(lazy(() => import('@/views/Mall/Exchange')))
const MallRecord = SuspenseComponent(lazy(() => import('@/views/Mall/Record')))
const MallCancel = SuspenseComponent(lazy(() => import('@/views/Mall/Cancel')))
const Profile = SuspenseComponent(lazy(() => import('@/views/Profile')))
const ProfileDetail= SuspenseComponent(lazy(() => import('@/views/Profile/Detail')))

const routes = [
  {
    path: '/*',
    title: '',
    element: <Layout />,
    children: [
      {
        path: '',
        title: '',
        element: <Main />,
        children: [
          {
            path: 'wealth',
            title: '理财列表',
            element: <Wealth />,
            needAuth: true,
          },
          {
            path: 'mall',
            title: '积分商城',
            element: <Mall />,
            needAuth: true,
          },
          {
            path: 'profile',
            title: '我的理财',
            element: <Profile />,
            needAuth: true,
          },
          { path: "", element: <Navigate to="/wealth" replace /> },
          { path: "*", element: <Navigate to="" replace /> },
        ]
      },
      {
        title: '积分商城',
        path: 'mall/detail',
        element: <MallDetail />,
        needAuth: true,
      },
      {
        title: '积分商城',
        path: 'mall/exchange',
        element: <MallExchange />,
        needAuth: true,
      },
      {
        title: '兑换列表',
        path: 'mall/record',
        element: <MallRecord />,
        needAuth: true,
      },
      {
        title: '兑换列表',
        path: 'mall/cancel',
        element: <MallCancel />,
        needAuth: true,
      },
      {
        title: '理财列表',
        path: 'wealth/:projectId',
        element: <WealthProduct />,
        needAuth: true,
      },
      {
        title: '理财详情',
        path: 'wealth/:projectId/:id',
        element: <WealthDetail />,
        needAuth: true,
      },
      {
        title: '我的理财',
        path: 'profile/:id',
        element: <ProfileDetail />,
        needAuth: true,
      },
      {
        title: '登录',
        path: 'login',
        element: <Login />,
        needAuth: false,
      },
      {
        title: '注册',
        path: 'signup',
        element: <Signup />,
        needAuth: false,
      },
      {
        title: '忘记密码',
        path: 'password-reset',
        element: <PasswordReset />,
        needAuth: false,
      },
    ]
  }
]

const RouteElements = () => {
  const elements = useRoutes(routes)
  return elements
}

const getRouteItem = path => getRoutes(routes, path)

const getRoutes = (routes, path) => {
  for (const item of routes) {
    if (item.path === path) return item
    else if (item.children) {
      const res = getRoutes(item.children, path)
      if (res) return res
    }
  }
  return null
}

export { RouteElements, getRouteItem }